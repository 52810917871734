import { defineStore } from "pinia";
import { useStorageAsync } from "@vueuse/core"

interface UserEvent {
  eventId: number;
  eventDescription:string,
  scheduledAt:string,
  hourCode:number,
  eventPrice:number,
  eventDuration:number,
  eventid:number,
  companyName:string,
  companyId:number,
  companyAdress:string,
  companyCity:string,
  companyZipCode:string,
  employeeName:string,
  // Add other properties as needed
}

export const useUserStore = defineStore({
    id:'userStore',
    state: () => ({
      userId:useStorageAsync('userId',0,sessionStorage),
      userRole:useStorageAsync('role',null,sessionStorage),
      userCompanyId: useStorageAsync('companyID',0,sessionStorage),
      userEmail : useStorageAsync('userEmail',null,sessionStorage),
      userFirstName: useStorageAsync('firstName',null,sessionStorage),
      userLastName:useStorageAsync('lastName',null,sessionStorage),
      userPhoneNumber: useStorageAsync('phoneNumber',null,sessionStorage),
      userEventsBefore: [],
      userEventsUpcoming:[]
    }),
    actions:{

      async login(p_email:string,p_password:string){
        try {
          const response = await fetch(`${process.env.VUE_APP_API_BASE}/api/Auth/login`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({  
                Email: p_email,
                Password: p_password })  
          });
  
          if (response.ok) {
              console.log("Request succeeded");
              const responseJSON = await response.json();
              this.userEmail=responseJSON.email;
              this.userFirstName=responseJSON.firstName;
              this.userLastName=responseJSON.lastName;
              this.userPhoneNumber=responseJSON.phoneNumber;
              this.userCompanyId=responseJSON.companyID;
              this.userRole=responseJSON.role;
              this.userId=responseJSON.userId;
  
              sessionStorage.setItem('userEmail',responseJSON.email);
              sessionStorage.setItem('userId',responseJSON.userId );
              sessionStorage.setItem("companyID", responseJSON.companyID);
              sessionStorage.setItem("firstName", responseJSON.firstName);
              sessionStorage.setItem("lastName", responseJSON.lastName);
              sessionStorage.setItem("role", responseJSON.role);
              sessionStorage.setItem("phoneNumber", responseJSON.phoneNumber);
  
              return true;
          } else {
            return false;
          }
      } catch (error) {
          console.error(error);
      }
      },

      async register(p_email:string,p_password:string,p_firstname:string,p_phonenumber:string) {
    
        try {
            const response = await fetch(`${process.env.VUE_APP_API_BASE}/api/Auth/register`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    Email: p_email,
                    Password: p_password,
                    FirstName:p_firstname,
                    PhoneNumber:p_phonenumber
                })
            });
    
            if (response.ok) {
                const responseJSON = await response.json();
                this.userEmail=responseJSON.email;
                this.userFirstName=responseJSON.firstName;
                this.userLastName=responseJSON.lastName;
                this.userPhoneNumber=responseJSON.phoneNumber;
                this.userCompanyId=responseJSON.companyID;
                this.userRole=responseJSON.role;
                this.userId=responseJSON.userId;

                sessionStorage.setItem('userEmail',responseJSON.email);
                sessionStorage.setItem('userId',responseJSON.userId );
                sessionStorage.setItem("companyID", responseJSON.companyID);
                sessionStorage.setItem("firstName", responseJSON.firstName);
                sessionStorage.setItem("lastName", responseJSON.lastName);
                sessionStorage.setItem("role", responseJSON.role);
                sessionStorage.setItem("phoneNumber", responseJSON.phoneNumber);
    
                return true;
            } else {
                return false
            }
        } catch (error) {
            console.error(error);
        }
      },

      async logout(){
        try{

          sessionStorage.clear();

          this.userEmail=null;
          this.userFirstName=null;
          this.userLastName=null;
          this.userPhoneNumber=null;
          this.userCompanyId=0;
          this.userRole=null;
          this.userId=0;

          await fetch(`${process.env.VUE_APP_API_BASE}/api/Auth/logout`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
          });

        }
        catch (error) {
          console.error(error);
        }
      },

      async  getEventsByCustomerId(userId:number){
          try{
            const response = await fetch(`${process.env.VUE_APP_API_BASE}/api/Event/user/${userId}`, {
              method: "GET",
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });
        
            if (response.ok) {
              const events = await response.json();
                  // Filter events before today and upcoming events

              const today = new Date();
              const formattedDate = today.getUTCFullYear() +
                    '-' + ('0' + (today.getUTCMonth() + 1)).slice(-2) +
                    '-' + ('0' + today.getUTCDate()).slice(-2);

              const beforeToday = events.filter((event: UserEvent) => {
                return event.scheduledAt < formattedDate;
            });

            const upcomingEvents = events.filter((event: UserEvent) => {
              return event.scheduledAt >= formattedDate;
            });

              // Assign the filtered events to your class property
              this.userEventsBefore = beforeToday;
              this.userEventsUpcoming = upcomingEvents;
            }
          }catch(e) {
              console.log(e);
          }
      },

      formatDate(date:Date){
      const pad = (num: number) => (num < 10 ? `0${num}` : num); // Helper function to add 0 before numbers
      const year = date.getFullYear();
      const month = pad(date.getMonth() + 1);
      const day = pad(date.getDate()).toString();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate
      },
    }
})