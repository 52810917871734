import { defineStore } from "pinia";

export interface EmployeeHour {
  closeAt:number;
  companyID:number;
  employeeID:number;
  id:number;
  openAt:number;
  weekDay:number;
}

export interface EmployeeListModel{
  employeeId: number;
  firstName: string;
}

export interface JobDescriptions{
  categorie: string;
  subItems: SubItems[];
}
export interface SubItems{
  description: string;
  duration: number;
  eventTypeId:number;
  price:number
}

export const useCurrentCompanyStore = defineStore({
    id:'CurrentCompanyStore',
    state: () => ({
        currentCompanyId : 0,
        companyName : "",
        addressLine1: "",
        addressLine2: "",  
        city: "",
        country: "",
        zipCode: "",  
        timezone: "",
        items:[] as JobDescriptions[],
        openHours: [], 
        allEvents: [],
        employeesList:[] as EmployeeListModel[],
        employeesHourList:[] as EmployeeHour[],
        photoUrlList:[],
        selectedEventId: null,
        selectedEventDescription: null,
        selectedEventDuration: null,
        selectedEventPrice: null,
        loading: false,
        error:null
    }),
    actions:{
        async getCompanyById(companyID:number){
            this.currentCompanyId = companyID
            try{
              await fetch(`${process.env.VUE_APP_API_BASE}/api/Company/${companyID}`, 
                {
                  method: "GET",
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  credentials: 'include',
                }
              )
              .then(
                (response) => response.json()
              )
              .then(
                (comp) => {
                  this.companyName= comp[0].companyName;
                  this.addressLine1 = comp[0].addressLine1;
                  this.addressLine2 = comp[0].addressLine2;  
                  this.city = comp[0].city; 
                  this.country = comp[0].country; 
                  this.zipCode = comp[0].zipCode;
                  this.items = comp[0].items;
                  this.employeesList= comp[0].employeeList;
                  this.employeesHourList = comp[0].employeeHours;
                  this.photoUrlList=comp[0].photoUrls;
                  // this.openHours = comp[0].openHours.split(",");       
                }
              )
            }catch(e) {
                console.log(e);
            }
        },

        async  getEvents(companyID:number){
          try{
            const today = new Date();
            const nextTreeMonthDay = new Date(today.getFullYear(), today.getMonth()+3, today.getDate());
            const d1 = this.formatDate(today);
            const d2 = this.formatDate(nextTreeMonthDay);
            const response = await fetch(`${process.env.VUE_APP_API_BASE}/api/Event/dates/${companyID}&${d1}&${d2}`, {
              method: "GET",
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });
        
            if (response.ok) {
              const events = await response.json();
              this.allEvents = events;
            }
          }catch(e) {
              console.log(e);
          }
        },
        
        formatDate(date:Date){
          const pad = (num: number) => (num < 10 ? `0${num}` : num); // Helper function to add 0 before numbers
          const year = date.getFullYear();
          const month = pad(date.getMonth() + 1);
          const day = pad(date.getDate()).toString();
          const formattedDate = `${year}-${month}-${day}`;
          return formattedDate
        },
    }
})