import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import CompanyPage from "../views/CompanyPage.vue";
import LoginPage from "../views/LoginPage.vue";
import RegisterPage from "../views/RegisterPage.vue";
import ReservationPage from "../views/ReservationPage.vue";
import UserPage from "../views/UserPage.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: '/company/:id',
    name: "companyPage",
    component: CompanyPage,
    // props: true  
    props: route => ({ id: Number(route.params.id) })
  },
  {
    path: '/company/:id/reservation',
    name: "reservationPage",
    component: ReservationPage,
    // props: true  
    props: route => ({ id: Number(route.params.id) })
  },
  {
    path: "/login",
    name: "loginPage",
    component: LoginPage,
  },
  { 
    path: "/register",
    name: "registerPage",
    component: RegisterPage,
  },
  {
    path: "/user/:id",
    name: "userPage",
    component: UserPage,
    props: route => ({ id: Number(route.params.id) })
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
