import { defineStore } from "pinia";

export const useCompaniesStore = defineStore({
    id:'CompaniesList',
    state: () => ({
        companiesList : [],
        loading: false,
        error:null
    }),
    actions:{
        async  getCompanies(){
            this.companiesList = []
            this.loading = true
            try{
                this.companiesList = await fetch(`${process.env.VUE_APP_API_BASE}/api/Company`, 
                {
                  method: "GET",
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  credentials: 'include',
                }
              )
              .then(
                (response) => response.json()
              )
            }
            catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        },
    }
})