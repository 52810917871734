import { defineStore } from "pinia";
  // Define the necessary interfaces
interface EmployeeInfo {
  employeeId: number;
  firstName: string;
}

interface EventTreeNode {
  employeeId: number;
  firstName: string | null;
  companyId: number;
  items: EventTreeNodeItem[] | null;
}

interface EventTreeNodeItem {
  scheduledAt: string | null; 
  subItems: EventTreeNodeSubItem[];
}

interface EventTreeNodeSubItem {
  scheduledHourCode: number | null;
  duration: number | null;
}

export interface daysList{
  date: string;
  dayName: string;
  dayMonth: string;
  day: string ;
  dayNumber:number;
  events: any[];
}

export interface EmployeeHour {
  closeAt:number;
  companyID:number;
  employeeID:number;
  id:number;
  openAt:number;
  weekDay:number;
}

export const useReservationDaysStore = defineStore({
    id:'ReservationStore',
    state: () => ({
      nextThreeMonthsDays : [] as daysList[], 
        loading: false,
        error:null,
        hourCode: null,
        hourSelected:null,
        dateSelected:null,
        dateSelectedFull:null,
        employeeSelected:null,
        timeDictionary: {
          0: '00:00',
          1: '00:15',
          2: '00:30',
          3: '00:45',
          4: '01:00',
          5: '01:15',
          6: '01:30',
          7: '01:45',
          8: '02:00',
          9: '02:15',
          10: '02:30',
          11: '02:45',
          12: '03:00',
          13: '03:15',
          14: '03:30',
          15: '03:45',
          16: '04:00',
          17: '04:15',
          18: '04:30',
          19: '04:45',
          20: '05:00',
          21: '05:15',
          22: '05:30',
          23: '05:45',
          24: '06:00',
          25: '06:15',
          26: '06:30',
          27: '06:45',
          28: '07:00',
          29: '07:15',
          30: '07:30',
          31: '07:45',
          32: '08:00',
          33: '08:15',
          34: '08:30',
          35: '08:45',
          36: '09:00',
          37: '09:15',
          38: '09:30',
          39: '09:45',
          40: '10:00',
          41: '10:15',
          42: '10:30',
          43: '10:45',
          44: '11:00',
          45: '11:15',
          46: '11:30',
          47: '11:45',
          48: '12:00',
          49: '12:15',
          50: '12:30',
          51: '12:45',
          52: '13:00',
          53: '13:15',
          54: '13:30',
          55: '13:45',
          56: '14:00',
          57: '14:15',
          58: '14:30',
          59: '14:45',
          60: '15:00',
          61: '15:15',
          62: '15:30',
          63: '15:45',
          64: '16:00',
          65: '16:15',
          66: '16:30',
          67: '16:45',
          68: '17:00',
          69: '17:15',
          70: '17:30',
          71: '17:45',
          72: '18:00',
          73: '18:15',
          74: '18:30',
          75: '18:45',
          76: '19:00',
          77: '19:15',
          78: '19:30',
          79: '19:45',
          80: '20:00',
          81: '20:15',
          82: '20:30',
          83: '20:45',
          84: '21:00',
          85: '21:15',
          86: '21:30',
          87: '21:45',
          88: '22:00',
          89: '22:15',
          90: '22:30',
          91: '22:45',
          92: '23:00',
          93: '23:15',
          94: '23:30',
          95: '23:45',
        },
    }),
    actions:{
    
      adjustEmployeeEventsWithDuration(duration: number) { 
        if (duration > 1) {
          this.nextThreeMonthsDays.forEach(element => {
            
            //Creer une copie des events pour pouvoir modifier la vrai liste sans utiliser des events modifies recursivement
            const deepCopyArray = JSON.parse(JSON.stringify(element.events));
            
            for (let i = deepCopyArray.length - 1; i >1 ; i--) {
              if (deepCopyArray[i].isScheduled === true) {
                for(let j=1;j<duration;j++){
                  element.events[i - j].isScheduled = true;
                }
              }
            }
          });
        }
      },

      async filterEmployeeEvents(employeeID: number, allEvents:EventTreeNode[],employeesHourList:EmployeeHour[]) {
        try {
          const filteredEvents = allEvents.filter((event) => event.employeeId === employeeID);
          const currentDate = new Date();
          const daysList : daysList[] = []
          const currentEmployeeHours =employeesHourList.filter((element)=>element.employeeID === employeeID);

          for (let i = 0; i < 30; i++) {
            const year = currentDate.getFullYear();
            const month = this.pad(currentDate.getMonth() + 1);
            const day = this.pad(currentDate.getDate()).toString();
            const formattedDate = `${year}-${month}-${day}`;
            const dayNumber = currentDate.getDay();
            const dayName = currentDate.toLocaleDateString('fr-FR', { weekday: 'long' });
            const dayMonth = currentDate.toLocaleDateString('fr-FR', { month: 'long' });

            //check open hours
            const numbersArray: number[] = this.createArrayFromPairs(dayNumber,currentEmployeeHours);
            
            const matchingEvents = filteredEvents.filter((event) =>
              event.items.some((item) => item.scheduledAt === formattedDate)
            );
            const eventList: any[] = [];
            
            //Create a list of events set to false by default, but true if an event is already scheduled a certain time
            for (let j = 0; j < numbersArray.length; j++) {
              const value1 = numbersArray[j];
              let duration = 1; // Default value in case subItem is not found
              const foundEvent = matchingEvents.find((event) =>
                event.items.some(
                  (item) =>
                    item.scheduledAt === formattedDate &&
                    item.subItems.some(
                      (subItem) => {
                        if (subItem.scheduledHourCode === value1) {
                          duration = subItem.duration || 1;
                          return true;
                        }
                        return false;
                      }
                    )
                )
              );
              const found = !!foundEvent; //It's used to ensure that found is explicitly a boolean value. If foundEvent is truthy, found will be true, and if foundEvent is falsy, found will be false. This can be useful in scenarios where you want to explicitly represent a boolean result, and it's a common JavaScript idiom.
              
              
              //Iterate over the eventList array to take account of the duration of each event
              if(found && duration >1){
                
                eventList.push({ scheduledHourCode: numbersArray[j], isScheduled: found });
                while(duration>1){
                  j+=1;
                  eventList.push({ scheduledHourCode: numbersArray[j], isScheduled: found });
                  duration -=1;
                }
              }else{
                eventList.push({ scheduledHourCode: value1, isScheduled: found });
              }
            }
            
            daysList.push({
              date: formattedDate,
              dayName: dayName,
              dayMonth: dayMonth,
              day: day,
              dayNumber:dayNumber,
              events: eventList,
            });

            currentDate.setDate(currentDate.getDate() + 1);
          }      
          this.nextThreeMonthsDays = daysList;
    
        } catch (e) {
          console.log(e);
        }
      },
      
      formatDate(date:Date){ // Helper function to add 0 before numbers
        const pad = (num: number) => (num < 10 ? `0${num}` : num); 
        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate()).toString();
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
      },

      pad(num: number){
        return num < 10 ? `0${num}` : num;
      },

      createArrayFromPairs(dayNumber:number,pairs: EmployeeHour[]): number[] {
        const currentDayOpenHours = pairs.filter((element)=>element.weekDay===dayNumber)
                                          .sort((a, b) => a.openAt - b.openAt);
        const resultArray: number[] = [];
      
        for (const pair of currentDayOpenHours) {
          const { openAt, closeAt } = pair;
          for (let i = openAt; i <= closeAt; i++) {
            resultArray.push(i);
          }
        }
        return resultArray;
      },
      
    }
})